import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { RouterModule, Routes } from '@angular/router';
import { ComponentsModule } from 'app/common/components/components.module';
import { NgJsonEditorModule } from 'ang-jsoneditor';
import { ArchwizardModule } from 'angular-archwizard';
import { PipeModule } from 'app/pipes/pipe.module';
import { UserListPage } from './user-list/user-list.page';
import { EditUserRoleModal } from './edit-user/edit-user-roles.page';
import { AddACRToEntityModal } from './acr-components/add-acr-to-entity.modal';



export const AdminRoutes: Routes = [
    { path: 'admin/users-list', component: UserListPage, canActivate: []}
];


@NgModule({
    imports: [
        CommonModule,
        ComponentsModule,
        RouterModule.forChild(AdminRoutes),
		NgJsonEditorModule,
		ArchwizardModule,
		PipeModule
    ],
    exports: [
		EditUserRoleModal,
		AddACRToEntityModal
	],
    declarations: [
		UserListPage,
		EditUserRoleModal,
		AddACRToEntityModal
    ],
    providers: [],
	entryComponents: [
		EditUserRoleModal,
		AddACRToEntityModal
	]
})
export class AdminModule { }
