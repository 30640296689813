import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { JsonEditorOptions } from 'ang-jsoneditor';
import { FormFieldDescription } from 'app/common/models/forms';
import { ActionCommand, SelectListitem } from 'app/common/models/models';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { iconCancel, iconEdit, iconPropertiesEdit, iconSave, iconTrash } from "app/common/utils/icons.utils";
import { DOMAINS_CONFIG, RestaurantProvider } from 'app/services/restaurant.service';

import { ActivatedRoute, Router } from '@angular/router';
import { SubmissionResult } from 'app/pages/forms/result-form-page/result-form-submission.page';
import { catchError } from 'rxjs/operators';
import { CommonValidators } from "app/common/components/form/validators/common.validator";
import { FORM_SUBMISSION_RESULT } from '../routing-constants';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Restaurant } from 'app/models/restaurant.model';

// declare var clientLib;
import { saveAs } from 'file-saver';
import { CertificationConfig } from '../CertificationBaseFormPage';
import { environment } from 'environments/environment';
import { BlockChainService } from 'app/services/blockchain.service';
import moment from 'moment';
import { AuthService } from 'app/services/auth.service';

@Component({
    selector: 'popup-evaluation-romanesco-form-page',
    templateUrl: './popup-evaluation-romanesco-form-page.html'
})

export class EditRomanescoEvaluation  implements OnInit {
	@Input() entityId;

	iconCancel = iconCancel;
	iconSave = iconSave;

    // public form: FormGroup = null;
	public menuInfo: FormGroup = null;
	public sectionCheck: FormGroup = null;
	public evaluation: FormGroup = null;
	public userEvaluation: FormGroup = null;
	public suggestedAndRecipe: FormGroup = null;
	public contactInfo: FormGroup = null;
	public deliveryInfo: FormGroup = null;
	public restaurantInfo: FormGroup = null;
	public usefullInfo: FormGroup = null;
	walletForm: FormGroup = null;
	public stringValue = null;
    public selectItemsAcc: SelectListitem[] = [];
    public initialItemAcc: SelectListitem = null;
	public selectItemsSocial: SelectListitem[] = [];
    public initialItemSocial: SelectListitem = null;
	public selectItemsProd: SelectListitem[] = [];
    public initialItemProd: SelectListitem = null
	public selectItemsPayment: SelectListitem[] = [];
    public initialItemPayment: SelectListitem = null;
	public selectItemsMenuType: SelectListitem[] = [];
    public initialItemMenuType: SelectListitem = null;
	public selectItemsAppetizers: SelectListitem[] = [];
    public initialItemAppetizers: SelectListitem = null;
	public selectItemsPastaAndSoups: SelectListitem[] = [];
    public initialItemPastaAndSoups: SelectListitem = null;
	public selectItemsMainCourses: SelectListitem[] = [];
    public initialItemMainCourses: SelectListitem = null;
	public selectItemsSideDishes: SelectListitem[] = [];
    public initialItemSideDishes: SelectListitem = null;
	public selectItemsDessertsAndCheeses: SelectListitem[] = [];
    public initialItemDessertsAndCheeses: SelectListitem = null;
	public selectItemsProducts: SelectListitem[] = [];
    public initialItemProducts: SelectListitem = null;
	public exampleCenter = [14.781447642043426, 41.130769884646625];  //coordinate del museo del sannio [long, lat]
	public _polygon: any = []
	public traditional:boolean = true;
	public isBtnDisabled: boolean= true;

	public data: any;
	private certifiedData: {
		message: any,
		signature: any
	};

	public get title() {
		if (this.restaurantInfo && this.restaurantInfo.value && this.restaurantInfo.value.sign) return "Valuta il ristorante " + this.restaurantInfo.value.sign;
		return "";
	}

	private _originalEntity;

	ngOnInit() {

		
		

		this._restaurantProvider.getRestaurant(DOMAINS_CONFIG.ROMANESCO, this.data.id).subscribe(params => {
			console.log(params);
			
			let id = params['id'];
			if (id != undefined || id != null) {
				this._restaurantProvider.getRestaurant(DOMAINS_CONFIG.ROMANESCO,id).subscribe((result) => {
					this.initForm();
					this._originalEntity = result;

					let indiceTecnico = 0;
					indiceTecnico += (((this._originalEntity.evaluation.evaluationStarter)?this._originalEntity.evaluation.evaluationStarter:0) / 19) * 0.20;
					indiceTecnico += (((this._originalEntity.evaluation.evaluationFirstAndSoup)?this._originalEntity.evaluation.evaluationFirstAndSoup:0) / 20) * 0.20;
					indiceTecnico += (((this._originalEntity.evaluation.evaluationSeconds)?this._originalEntity.evaluation.evaluationSeconds:0) / 25) * 0.20;
					indiceTecnico += (((this._originalEntity.evaluation.evaluationSide)?this._originalEntity.evaluation.evaluationSide:0) / 19) * 0.10;
					indiceTecnico += (((this._originalEntity.evaluation.evaluationDessert)?this._originalEntity.evaluation.evaluationDessert:0) / 19) * 0.10;
					indiceTecnico += (((this._originalEntity.evaluation.evaluationWine)?this._originalEntity.evaluation.evaluationWine:0) / 8) * 0.05;
					indiceTecnico += (((this._originalEntity.evaluation.evaluationOil)?this._originalEntity.evaluation.evaluationOil:0) / 3) * 0.03;
					indiceTecnico += (((this._originalEntity.evaluation.evaluationBred)?this._originalEntity.evaluation.evaluationBred:0) / 3) * 0.03;
					indiceTecnico += (((this._originalEntity.evaluation.useTipicalProduct)?this._originalEntity.evaluation.useTipicalProduct:0) / 5) * 0.04;
					indiceTecnico += (((this._originalEntity.evaluation.evaluationMenu)?this._originalEntity.evaluation.evaluationMenu:0) / 1) * 0.05;
					
					this._originalEntity.evaluation.indiceTecnico = (Math.round(indiceTecnico*100)) + "%";

					this.restaurantInfo.patchValue(this.unmarshalData(this._originalEntity));

					if (this._originalEntity && this._originalEntity.evaluation && this._originalEntity.evaluation.usersEvaluation && this._originalEntity.evaluation.usersEvaluation.length>0) {
						let userEvaluation = (<any[]>this._originalEntity.evaluation.usersEvaluation).find((evaluation) => {
							return evaluation.userId == this._authService.getClaims().sub;
						})
						if (userEvaluation) {
							this.userEvaluation.patchValue(userEvaluation);
							this.userEvaluation.controls.certificationDate.patchValue(moment().format());
						}
					}
				})
			}
			else {
				this.initForm();
			}
		});
	}

    constructor(private _chainService: BlockChainService, public activeModal: NgbActiveModal, private _fb: FormBuilder,private  _restaurantProvider: RestaurantProvider, private _authService: AuthService) {
		
    }

	

	private initForm = () => {
		this.userEvaluation = this._fb.group({
			evaluationDescription: [null],
			indiceQualitativo: [null],
			directExperience: [null],
			evaluationValidated: [null],
			certificationDate: [moment().format()],
			email: [this._authService.getClaims().email],
			name: [this._authService.getClaims().name],
			userId: [this._authService.getClaims().sub],
			
		})
		this.evaluation = this._fb.group({
			evaluationDate: [null],
			companyEvaluation: [null],

			evaluationOil: [null],
			evaluationBred: [null],
			useTipicalProduct: [null],
			tipicalMenu: [null],
			evaluationStarter: [null],
			evaluationFirstAndSoup: [null],
			evaluationSeconds: [null],
			evaluationSide: [null],
			evaluationDessert: [null],
			evaluationMenu: [null],
			overallEvaluation: [0],

			evaluationWine: [0],
			indiceTecnico: [null]
		});


		this.walletForm =  this._fb.group({
			privateKey: [this.data.privateKey, Validators.required]
		});
		
		this.sectionCheck = this._fb.group({
			firstCardCheck: [false],
			secondCardCheck: [false],
			thirdCardCheck: [false]
		});

		this.deliveryInfo = this._fb.group({
			// logo: [null, Validators.required],
			paymentType: [null],
			menuType: [null],
			deliveryDays: [null],
			lunchDeliveryHours: [null],
			dinnerDeliveryHours: [null],
			deliverySolutionsTimeInterval: [null],
			sameMenu: [false],
			// menuImage: [null, Validators.required],
			orderRestrictions: [null],
			menuSectionsToExclude: [null],
			notes: [null],
		});

		// TODO: aggiungere controllo immissione min elements
		this.menuInfo = this._fb.group({
			appetizers: [null, CommonValidators.arrayMinItemsValidator(0, "menuLabel.appetizersMinValues")],
			pastaAndSoups: [null, CommonValidators.arrayMinItemsValidator(0, "menuLabel.pastaAndSoupsMinValues")],
			mainCourses: [null,CommonValidators.arrayMinItemsValidator(0, "menuLabel.mainCoursesMinValues")],
			sideDishes: [null,CommonValidators.arrayMinItemsValidator(0, "menuLabel.sideDishesMinValues")],
			dessertsAndCheeses: [null,CommonValidators.arrayMinItemsValidator(0, "menuLabel.dessertAndCheesesMinValues")],

			appetizersOther: [null],
			pastaAndSoupsOther: [null],
			mainCoursesOther: [null],
			sideDishesOther: [null],
			dessertsAndCheesesOther: [null],

			menuImage: [null],
		});

		let deliveryMenu = this._fb.group({
			menuImage: [null],
		});

		let wineInfo = this._fb.group({
			docgWines: [null],
			docWines: [null],
			igtWines: [null],
			houseWine: [null],
			wineListImage: [null],
		});

		let oilInfo =this._fb.group({
			oils: [null],
			oilsListImage: [null],
		});

		let breadInfo =this._fb.group({
			breads: [null],
			breadsListImage: [null],
		});

		let productInfo =this._fb.group({
			products: [null],
			additionalProducts: [null]
		});

		this.suggestedAndRecipe = this._fb.group({
			imageSuggested: [null, Validators.required],
			appetizer: [null],
			dessert: [null],
			firstDish: [null],
			secondDish: [null],
			ingredients: [null, Validators.required],
			name: [null, Validators.required],
			preparation: [null],
			chefName: [null, Validators.required],
			suggestedByChef: [null, Validators.required]
		});


		this.contactInfo =this._fb.group({
			firstName: [null, Validators.required],
			lastName: [null, Validators.required],
       		phone: [null, [ Validators.required, Validators.pattern("^[0-9]*$")]],
       		// phone: [null, [ Validators.required, Validators.pattern("^[0-9]*$"), Validators.minLength(10), Validators.maxLength(10)]],
			email: [null,[Validators.email, Validators.required]]
		});

	
		this.usefullInfo =this._fb.group({
			openingTime: [null, Validators.required],
			reservation: [false],
			weeklyClosing: [null, Validators.required],
			annualClosing: [null, Validators.required],
			creditCards: [null],
			accessibility: [null],
			parking: [false],
			petsAllowed: [false],
			wifi: [false],
			internalSeats: [null],
			externalSeats: [null],
			kitchenType: [null, Validators.required],
			specialDietsDishes: [null],
			specialProductsUsage: [false],
			priceRange: [null, Validators.required]
		});

		this.restaurantInfo =this._fb.group({
			id: [null],
			logo: [null, Validators.required],
			businessName: [null, Validators.required],
			sign: [null, Validators.required],
			// sdiOrPec: [null, Validators.required],
			vatNumber: [null, Validators.required],
			representative: [null, Validators.required],
			address: [null, Validators.required],
			zipCode: [null, Validators.required],
			city: [null, Validators.required],
			province: [null, Validators.required],
			phone: [null, [Validators.pattern("^[0-9]*$")]],
			fipePartner: [false],
			fipeCardNumber: [null],
			mobilePhone: [null, [Validators.pattern("^[0-9]*$")]],
			email: [null, Validators.email],
			website: [null],
			social: [null],
			socialFacebook: [null],
			socialInstagram:[null],
			// socialTikTok: [null],
			description: [null],
			ownerStory: [null],
			
			usefullInfo: this.usefullInfo,
			contactInfo: this.contactInfo,
			
			menuInfo: this.menuInfo,
			providesData: [false],
			deliveryMenu: deliveryMenu,
			wineInfo: wineInfo,
			oilInfo: oilInfo,
			breadInfo: breadInfo,
			productInfo: productInfo,
			suggestedAndRecipe: this.suggestedAndRecipe,
			deliveryInfo: this.deliveryInfo,
			sectionCheck: this.sectionCheck,
			evaluation: this.evaluation
		});

		let accessibility = [
			{id: "servizi per disabili (rampe e servizi igienici)", name: "servizi per disabili (rampe e servizi igienici)"},
			{id: "assenza di barriere architettoniche", name: "assenza di barriere architettoniche"},
		];

		this.selectItemsAcc = accessibility.map(m => new SelectListitem(m.id, m.name));
		this.initialItemAcc = this.selectItemsAcc[0];


		let social = [
			{id: "Facebook", name: "Facebook"},
			{id: "Instagram", name: "Instagram"},
			{id: "Twitter", name: "Twitter"},
			{id: "LinkedIn", name: "LinkedIn"},
			{id: "TikTok", name: "TikTok"},
		];

		this.selectItemsSocial = social.map(m => new SelectListitem(m.id, m.name));
		this.initialItemSocial = this.selectItemsSocial[0];

		let payment = [
			{id: "Pagamento alla consegna", name: "Pagamento alla consegna"},
			{id: "Ritira e paga presso il locale", name: "Ritira e paga presso il locale"},
			{id: "PayPal", name: "PayPal"},
			{id: "Stripe", name: "Stripe"},
	
		];

		this.selectItemsPayment = payment.map(m => new SelectListitem(m.id, m.name));
		this.initialItemPayment = this.selectItemsPayment[0];


		let menuType = [
			{id: "Confezionati caldi", name: "Confezionati caldi"},
			{id: "Confezionati freddi", name: "Confezionati freddi"},
			{id: "Sottovuoto", name: "Sottovuoto"},
		];

		this.selectItemsMenuType = menuType.map(m => new SelectListitem(m.id, m.name));
		this.initialItemMenuType = this.selectItemsMenuType[0];


		let productsType = [
			{id: "Vegetariana", name: "Vegetariana"},
			{id: "Vegana", name: "Vegana"},
			{id: "Senza Glutine", name: "Senza Glutine"},
			{id: "Senza Lattosio", name: "Senza Lattosio"},
	
		];

		this.selectItemsProd = productsType.map(m => new SelectListitem(m.id, m.name));
		this.initialItemProd = this.selectItemsProd[0];


		let appetizers = [
			{id: "Capocollo di Castelgandolfo", name: "Capocollo di Castelgandolfo"},
			{id: "Corallina (di Montegabbione) ", name: "Corallina (di Montegabbione) "},
			{id: "Insalata di zampi", name: "Insalata di zampi"},
			{id: "Testina di vitello", name: "Testina di vitello"},
			{id: "Crostino con burro e alici", name: "Crostino con burro e alici"},
			{id: "Coratella con Carciofi", name: "Coratella con Carciofi"},
			{id: "Vignarola", name: "Vignarola"},
			{id: "Parmigiana di Gobbi", name: "Parmigiana di Gobbi"},
			{id: "Fava, pecorino e bruschetta", name: "Fava, pecorino e bruschetta"},
			{id: "Insalata di mare", name: "Insalata di mare"},
			{id: "Cazzimperio", name: "Cazzimperio"},
			{id: "Provatura fritta", name: "Provatura fritta"},
			{id: "Panzanella alla romana", name: "Panzanella alla romana"}
		];

		this.selectItemsAppetizers = appetizers.map(m => new SelectListitem(m.id, m.name));
		this.initialItemAppetizers = this.selectItemsAppetizers[0];


		let pastaAndSoups = [
			{id: "Bucatini alla Amatriciana", name: "Bucatini alla Amatriciana"},
			{id: "Bucatini alla gricia", name: "Bucatini alla gricia"},
			{id: "Rigatoni con pajata ", name: "Rigatoni con pajata "},
			{id: "Spaghetti alla carbonara", name: "Spaghetti alla carbonara"},
			{id: "Spaghetti cacio e pepe", name: "Spaghetti cacio e pepe"},
			{id: "Tonnarelli al sugo di coda", name: "Tonnarelli al sugo di coda"},
			{id: "Penne all’arrabbiata", name: "Penne all’arrabbiata"},
			{id: "Bavette al sugo di tonno", name: "Bavette al sugo di tonno"},
			{id: " ", name: " "},
			{id: "Stracciatella alla romana", name: "Stracciatella alla romana"},
			{id: "Pasta e fagioli", name: "Pasta e fagioli"},
			{id: "Pasta e lenticchie", name: "Pasta e lenticchie"},
			{id: "Pasta e ceci", name: "Pasta e ceci"},
			{id: "Pasta e ceci con vongole", name: "Pasta e ceci con vongole"},
			{id: "Pasta e patate", name: "Pasta e patate"},
			{id: "Pasta e broccoli in brodo d’arzilla", name: "Pasta e broccoli in brodo d’arzilla"},
			{id: "Minestra di riso e lenticchie", name: "Minestra di riso e lenticchie"},
			{id: "Minestre di quadrucci in brodo di pesce", name: "Minestre di quadrucci in brodo di pesce"},
	
		];

		this.selectItemsPastaAndSoups = pastaAndSoups.map(m => new SelectListitem(m.id, m.name));
		this.initialItemPastaAndSoups = this.selectItemsPastaAndSoups[0];

		
		let mainCourses = [
			{id:  "Abbacchio alla cacciatora ", name: "Abbacchio alla cacciatora"},
			{id:  "Grigliata mista di interiora animelle, fegato, pajata e granelli  ", name: "Grigliata mista di interiora animelle, fegato, pajata e granelli "},
			{id:  "Braciolette (costolette) d’abbacchio a scottadito ", name: "Braciolette (costolette) d’abbacchio a scottadito"},
			{id:  "Coda alla vaccinara ", name: "Coda alla vaccinara"},
			{id:  "Coratella con carciofi ", name: "Coratella con carciofi"},
			{id:  "Fagioli e cotiche ", name: "Fagioli e cotiche"},
			{id:  "Garofolato di bue ", name: "Garofolato di bue"},
			{id:  "Involtini alla romana ", name: "Involtini alla romana"},
			{id:  "Padellotto alla macellara ", name: "Padellotto alla macellara"},
			{id:  "Pajata alla romana (in umido) ", name: "Pajata alla romana (in umido)"},
			{id:  "Pajata alla griglia  ", name: "Pajata alla griglia "},
			{id:  "Petto di vitello alla fornaia ", name: "Petto di vitello alla fornaia"},
			{id:  "Pollo alla romana con peperoni ", name: "Pollo alla romana con peperoni"},
			{id:  "Porchetta dei castelli romani ", name: "Porchetta dei castelli romani"},
			{id:  "Rognoni trifolati ", name: "Rognoni trifolati"},
			{id:  "Saltimbocca alla romana ", name: "Saltimbocca alla romana"},
			{id:  "Vignarola ", name: "Vignarola"},
			{id:  "Trippa alla romana  ", name: "Trippa alla romana"},
			{id:  "Testarella d’abbacchio al forno con patate ", name: "Testarella d’abbacchio al forno con patate "},
			{id:  "Lingua di vitella bollita ", name: "Lingua di vitella bollita"},
			{id:  "Fritto misto alla romana (funghi, zucchine, carciofi, broccoli e mela ecc.) ", name: "Fritto misto alla romana (funghi, zucchine, carciofi, broccoli e mela ecc.)"},
			{id:  "Animelle d’abbacchio in diverse modalità ", name: "Animelle d’abbacchio in diverse modalità"},
			{id:  "Spuntatura di maiale con i gobbi", name: "Spuntatura di maiale con i gobbi"},
			{id:  "Abbacchio brodettato ", name: "Abbacchio brodettato"},
			{id:  "Aliciotti fritti ", name: "Aliciotti fritti "},
			{id:  "Baccalà in bianco", name: "Baccalà in bianco"},
			{id:  "Filetto di baccalà fritto", name: "Filetto di baccalà fritto"},
			{id:  "Baccalà in umido ", name: "Baccalà in umido"},
			{id:  "Baccalà in guazzetto alla romana ", name: "Baccalà in guazzetto alla romana"},
			{id:  "Aliciotti con indivia", name: "Aliciotti con indivia"},
			{id:  "Ciriole con piselli ", name: "Ciriole con piselli"},
			{id:  "Mazzancolle al vino bianco (o al coccio) ", name: "Mazzancolle al vino bianco (o al coccio)"},
			{id:  "Palombo con piselli e alla romana", name: "Palombo con piselli e alla romana"},
			{id:  "Triglie con uvetta e pinoli ", name: "Triglie con uvetta e pinoli"},
			{id:  "Lumache alla romana ", name: "Lumache alla romana"},
			{id:  "Frittata con alici ", name: "Frittata con alici"},
			{id:  "Ciriole in umido ", name: "Ciriole in umido"},
			{id:  "Palombo fritto", name: "Palombo fritto"}
		];

		this.selectItemsMainCourses = mainCourses.map(m => new SelectListitem(m.id, m.name));
		this.initialItemMainCourses = this.selectItemsMainCourses[0];

		
		let sideDishes = [
			{id:  "Carciofi alla romana ", name: "Carciofi alla romana "},
			{id:  "Cicoria in padella o all’agro ", name: "Cicoria in padella o all’agro "},
			{id:  "Fave fresche al guanciale ", name: "Fave fresche al guanciale"},
			{id:  "Puntarelle con salsa d’alici ", name: "Puntarelle con salsa d’alici "},
			{id:  "Broccoletti romani all’agro o in padella ", name: "Broccoletti romani all’agro o in padella "},
			{id:  "Pomodoro e rughetta ", name: "Pomodoro e rughetta "},
			{id:  "Carciofo alla giudia ", name: "Carciofo alla giudia "},
			{id:  "Carciofi fritti in pastella ", name: "Carciofi fritti in pastella"},
			{id:  "Vignarola ", name: "Vignarola "},
			{id:  "Piselli al prosciutto ", name: "Piselli al prosciutto "},
			{id:  "Misticanza ", name: "Misticanza "},
			{id:  "Broccoli ", name: "Broccoli "},
			{id:  "Cavolfiori ", name: "Cavolfiori"},
			{id:  "Finocchi ", name: "Finocchi" },
			{id:  "Gobbi ", name: "Gobbi "},
			{id:  "Fagiolini a corallo ", name: "Fagiolini a corallo "},
			{id:  "Zucchine romanesche ", name: "Zucchine romanesche "},
			{id:  "Bieta all’agro ", name: "Bieta all’agro "},
			{id:  "Fior di zucca fritti ", name: "Fior di zucca fritti "},
			{id:  "Pomodori al riso", name: "Pomodori al riso"},
			
	
		];

		this.selectItemsSideDishes = sideDishes.map(m => new SelectListitem(m.id, m.name));
		this.initialItemSideDishes = this.selectItemsSideDishes[0];

				
		let dessertsAndCheeses = [
			{id:  "Torta di ricotta ", name: "Torta di ricotta "},
			{id:  "Spuma di ricotta condita ", name: "Spuma di ricotta condita"},
			{id:  "Maritozzi ", name: "Maritozzi"},
			{id:  "Fragole all’aceto", name: "Fragole all’aceto"},
			{id:  "Crostata di ricotta ", name: "Crostata di ricotta"},
			{id:  "Crostata di marmellata di visciole ", name: "Crostata di marmellata di visciole "},
			{id:  "Frittelle di mele ", name: "Frittelle di mele "},
			{id:  "Pangiallo ", name: "Pangiallo"},
			{id:  "Provatura ", name: "Provatura"},
			{id:  "Fave dei morti ", name: "Fave dei morti "},
			{id:  "Pecorino romano ", name: "Pecorino romano "},
			{id:  "Caciotta romana ", name: "Caciotta romana "},
			{id:  "Ricotta romana ", name: "Ricotta romana "},

			
	
		];

		this.selectItemsDessertsAndCheeses = dessertsAndCheeses.map(m => new SelectListitem(m.id, m.name));
		this.initialItemDessertsAndCheeses = this.selectItemsDessertsAndCheeses[0];


		let products = [
			{id:  "Olio - Olio extra vergine di oliva 'Passio' - OP Latium", name: "Olio - Olio extra vergine di oliva 'Passio' - OP Latium"},
			{id:  "Olio - Olio extra vergine di oliva Olio di Roma IGP - OP latium", name: "Olio - Olio extra vergine di oliva Olio di Roma IGP - OP latium"},
			{id:  "Olio - Olio extra vergine di oliva Sabina DOP - Azienda Agricola DueNoveSei", name: "Olio - Olio extra vergine di oliva Sabina DOP - Azienda Agricola DueNoveSei"},
			{id:  "Olio - Olio extra vergine di oliva Sabina DOP 'Etichetta Nera' - Azienda Agricola Silvi Sabina Sapori.", name: "Olio - Olio extra vergine di oliva Sabina DOP 'Etichetta Nera' - Azienda Agricola Silvi Sabina Sapori."},
			{id:  "Olio - Olio extra vergine di oliva Sabina DOP 'Riserva' - Frantoio Oleario F.lli Narducci", name: "Olio - Olio extra vergine di oliva Sabina DOP 'Riserva' - Frantoio Oleario F.lli Narducci"},
			{id:  "Olio - Olio extra vergine di oliva Sabina DOP 'Colle Difesa' - Azienda Agricola Colle Difesa", name: "Olio - Olio extra vergine di oliva Sabina DOP 'Colle Difesa' - Azienda Agricola Colle Difesa"},
			
			{id:  "Vino - Cesanese di Olevano Romano DOP 'Spinello' - Azienda Agricola Selva Spina", name: "Vino - Cesanese di Olevano Romano DOP 'Spinello' - Azienda Agricola Selva Spina"},
			{id:  "Vino - Frascati Superiore DOCG - Castel De Paolis", name: "Vino - Frascati Superiore DOCG - Castel De Paolis"},
			{id:  "Vino - Frascati Superiore DOCG Riserva 'Sesto 21' - Alma Vini", name: "Vino - Frascati Superiore DOCG Riserva 'Sesto 21' - Alma Vini"},
			{id:  "Vino - Frascati Superiore DOCG Bio '1960' - Alma Vini", name: "Vino - Frascati Superiore DOCG Bio '1960' - Alma Vini"},
			{id:  "Vino - Lazio IGP Syrah 'Sesto 21' - Alma Vini", name: "Vino - Lazio IGP Syrah 'Sesto 21' - Alma Vini"},
			{id:  "Vino - Lazio IGP Bianco 'Jacobini' - Azienda Agricola Carafa Jacobini", name: "Vino - Lazio IGP Bianco 'Jacobini' - Azienda Agricola Carafa Jacobini"},
			{id:  "Vino - Lazio IGP Malvasia Puntinata 'Solo Mia' - Casale Vallechiesa", name: "Vino - Lazio IGP Malvasia Puntinata 'Solo Mia' - Casale Vallechiesa"},
			{id:  "Vino - Lazio IGT Malvasia Puntinata 'Galatea' - Iacoponi Lorenzo - Eredi dei Papi", name: "Vino - Lazio IGT Malvasia Puntinata 'Galatea' - Iacoponi Lorenzo - Eredi dei Papi"},
			{id:  "Vino - Lazio IGT Chardonnay 'Incanto' - Azienda Agricola Beralrdi Giuseppe", name: "Vino - Lazio IGT Chardonnay 'Incanto' - Azienda Agricola Beralrdi Giuseppe"},
			{id:  "Vino - Lazio IGT Cesanese - Cantine Volpetti", name: "Vino - Lazio IGT Cesanese - Cantine Volpetti"},
			{id:  "Vino - Lazio IGP Bellone Spumante Brut 'MareDivino' - Azienda Agricola Casa Divina Provvidenza ", name: "Vino - Lazio IGP Bellone Spumante Brut 'MareDivino' - Azienda Agricola Casa Divina Provvidenza "},
			{id:  "Vino - Lazio IGT Bombino Spumante Brut 'Enfasi' - Società Agricola Parvus Ager", name: "Vino - Lazio IGT Bombino Spumante Brut 'Enfasi' - Società Agricola Parvus Ager"},
			{id:  "Vino - Roma DOC Bianco Classico 'Ad Decimum' - Emanuele Ranchella", name: "Vino - Roma DOC Bianco Classico 'Ad Decimum' - Emanuele Ranchella"},
			{id:  "Vino - Roma DOC Rosso 'Julius' - Azienda Agricola Pesoli Giulio", name: "Vino - Roma DOC Rosso 'Julius' - Azienda Agricola Pesoli Giulio"},
			{id:  "Vino - Roma DOC Rosso 'Vinea Domini' - Gotto d’Oro ", name: "Vino - Roma DOC Rosso 'Vinea Domini' - Gotto d’Oro "},

			{id:  "Birra - 14-Bock - Podere 676 ", name: "Birra - 14-Bock - Podere 676 "},
			{id:  "Birra - Braggot - Podere 676 ", name: "Birra - Braggot - Podere 676 "},
			{id:  "Birra - Hausbier - JJ Brewery ", name: "Birra - Hausbier - JJ Brewery "},
			{id:  "Birra - End Game - JJ Brewery ", name: "Birra - End Game - JJ Brewery "},
			{id:  "Birra - 166 Ettari - Mou ", name: "Birra - 166 Ettari - Mou "},
			{id:  "Birra - Disintegrate - Spellbook ", name: "Birra - Disintegrate - Spellbook "},
			{id:  "Birra - Velvet - Oxiana", name: "Birra - Velvet - Oxiana"},
			{id:  "Birra - Cashmere - Mostoitaliano ", name: "Birra - Cashmere - Mostoitaliano "},
			{id:  "Birra - Murika - Aimara ", name: "Birra - Murika - Aimara "},
			{id:  "Birra - Tempura Crunch - Rebel’s", name: "Birra - Tempura Crunch - Rebel’s"},
			{id:  "Birra - Mama Killa - Damer", name: "Birra - Mama Killa - Damer"},
			{id:  "Birra - Oersted Imperial Porter - Barleyway-Ritual Lab ", name: "Birra - Oersted Imperial Porter - Barleyway-Ritual Lab "},

			{id:  "Formaggio - Mozzarella di Bufala Buona - Azienda Agricola D’Angelo Giancarlo ", name: "Formaggio - Mozzarella di Bufala Buona - Azienda Agricola D’Angelo Giancarlo "},
			{id:  "Formaggio - Primo Sale - Fattoria Colle San Nicola", name: "Formaggio - Primo Sale - Fattoria Colle San Nicola"},
			{id:  "Formaggio - Stagionato di Vacca - Agricoltura Nuova", name: "Formaggio - Stagionato di Vacca - Agricoltura Nuova"},
			{id:  "Formaggio - Pecorino Stagionato - Agricoltura Nuova", name: "Formaggio - Pecorino Stagionato - Agricoltura Nuova"},
			{id:  "Formaggio - Pecorino Gran Riserva Cacio Ercole - La Quercia ", name: "Formaggio - Pecorino Gran Riserva Cacio Ercole - La Quercia "},
			{id:  "Formaggio - Ricotta di Capra - Alchimista Lactis", name: "Formaggio - Ricotta di Capra - Alchimista Lactis"},
			{id:  "Formaggio - Soldo di Cacio - Caseificio De Juliis Enio e Figli", name: "Formaggio - Soldo di Cacio - Caseificio De Juliis Enio e Figli"},

			{id:  "Pane e prodotto da forno - Ciriola Romana - Pane e Dolci di Scorsoni Aronne", name: "Pane e prodotto da forno - Ciriola Romana - Pane e Dolci di Scorsoni Aronne"},
			{id:  "Pane e prodotto da forno - Casereccio Bianco - Pane e Dolci di Scorsoni Aronne", name: "Pane e prodotto da forno - Casereccio Bianco - Pane e Dolci di Scorsoni Aronne"},
			{id:  "Pane e prodotto da forno - Ciriola Romana - L’Altro Forno", name: "Pane e prodotto da forno - Ciriola Romana - L’Altro Forno"},
			{id:  "Pane e prodotto da forno - Brutti ma Buoni - L’Altro Forno", name: "Pane e prodotto da forno - Brutti ma Buoni - L’Altro Forno"},
			{id:  "Pane e prodotto da forno - Ciriola Romana - Panificio Sfizi di Grano", name: "Pane e prodotto da forno - Ciriola Romana - Panificio Sfizi di Grano"},
			{id:  "Pane e prodotto da forno - Rosetta - Graziani Italian Food", name: "Pane e prodotto da forno - Rosetta - Graziani Italian Food"},
			{id:  "Pane e prodotto da forno - Ciamberlenghe al Marsala - Graziani Italian Food", name: "Pane e prodotto da forno - Ciamberlenghe al Marsala - Graziani Italian Food"},
			{id:  "Pane e prodotto da forno - Pane Senza Sale - OL FOODLE", name: "Pane e prodotto da forno - Pane Senza Sale - OL FOODLE"},
			{id:  "Pane e prodotto da forno - Pane al Cioccolato - OL FOODLE", name: "Pane e prodotto da forno - Pane al Cioccolato - OL FOODLE"},
			{id:  "Pane e prodotto da forno - Pane Senza Sale - Forno Pane degli Angeli", name: "Pane e prodotto da forno - Pane Senza Sale - Forno Pane degli Angeli"},
			{id:  "Pane e prodotto da forno - Pane di Cave Scuro - Antichi Sapori", name: "Pane e prodotto da forno - Pane di Cave Scuro - Antichi Sapori"},
			{id:  "Pane e prodotto da forno - Sciapetto - Didan", name: "Pane e prodotto da forno - Sciapetto - Didan"},
			{id:  "Pane e prodotto da forno - Saporgrano - Didan", name: "Pane e prodotto da forno - Saporgrano - Didan"},
			{id:  "Pane e prodotto da forno - Crostatina alla visciola - Didan", name: "Pane e prodotto da forno - Crostatina alla visciola - Didan"},
			{id:  "Pane e prodotto da forno - Pane di Grano Duro Tumminia - Triticum", name: "Pane e prodotto da forno - Pane di Grano Duro Tumminia - Triticum"},
			{id:  "Pane e prodotto da forno - Pane al Cioccolato Fondente e Nocciole - Triticum", name: "Pane e prodotto da forno - Pane al Cioccolato Fondente e Nocciole - Triticum"},
			
			{id:  "Gusto gelato - Latte fresco", name: "Gusto gelato - Latte fresco"},
			{id:  "Gusto gelato - Ricotta Romana DOP", name: "Gusto gelato - Ricotta Romana DOP"},
			{id:  "Gusto gelato - Frutta fresca/disidratata di varietà locali", name: "Gusto gelato - Frutta fresca/disidratata di varietà locali"},
			{id:  "Gusto gelato - Miele", name: "Gusto gelato - Miele"},
			{id:  "Gusto gelato - Olio extra vergine di oliva", name: "Gusto gelato - Olio extra vergine di oliva"},
			{id:  "Gusto gelato - Vino Cannellino di Frascati DOCG", name: "Gusto gelato - Vino Cannellino di Frascati DOCG"},
			{id:  "Gusto gelato - Vino Roma DOC/Lazio IGP Malvasia Puntinata (o Malvasia del Lazio)", name: "Gusto gelato - Vino Roma DOC/Lazio IGP Malvasia Puntinata (o Malvasia del Lazio)"},
			
			{id:  "zucchina romanesca ", name: "Zucchina romanesca "},
			{id:  "broccolo romanesco ", name: "Broccolo romanesco"},
			{id:  "carciofo romanesco", name: "Carciofo romanesco"},
			{id:  "pecorino romano ", name: "Pecorino romano "},
			{id:  "ricotta romana  ", name: "Ricotta romana  "},
			{id:  "abbacchio romano ", name: "Abbacchio romano "},
			{id:  "Porchetta di Ariccia IGP ", name: "Porchetta di Ariccia IGP "},
			{id:  "Guanciale ", name: "Guanciale "},
			{id:  "Mortadella viterbese ", name: "Mortadella viterbese "},
			{id:  "fiori di zucca ", name: "Fiori di zucca "},
			{id:  "puntarelle di cicoria ", name: "Puntarelle di cicoria  "},
			{id:  "filetti di baccalà", name: "Filetti di baccalà "},
			{id:  "Amaretti", name: "Amaretti"},
			{id:  "Ciambelle al vino ", name: "Ciambelle al vino"},
			{id:  "Crostatino ripieno ", name: "Crostatino ripieno "},
			{id:  "Fetticciole nere e bianche", name: "Fetticciole nere e bianche "},
			{id:  "Fettuccine ", name: "Fettuccine "},
			{id:  "Maltagliati o fregnacce ", name: "Maltagliati o fregnacce"},
			{id:  "Pizza bianca ", name: "Pizza bianca"},
			{id:  "Liquore di genziana ", name: "Liquore di genziana "},
			{id:  "Liquore nocino", name: "Liquore nocino "},
			{id:  "Rattafia ciociara ", name: "Rattafia ciociara "},
			{id:  "Sambuca romana", name: "Sambuca romana"}
		];

		this.selectItemsProducts = products.map(m => new SelectListitem(m.id, m.name));
		this.initialItemProducts = this.selectItemsProducts[0];

	}

	private marshalData = (restaurant: any): any => {
		return restaurant;
	}

	private unmarshalData = (restaurant: any): any => {
		return restaurant;
	}
	
	public close = (): void => {
		this.activeModal.close(false);
    }

    public save() {

        if (this.userEvaluation.valid) {
			let certData: any = new Object();
			Object.assign(certData, this.restaurantInfo.value);
			if (this.userEvaluation.value.evaluationDescription)
				certData.evaluation.evaluationDescription = this.userEvaluation.value.evaluationDescription;
			if (this.userEvaluation.value.indiceQualitativo)
				certData.evaluation.indiceQualitativo = this.userEvaluation.value.indiceQualitativo;
			if (this.userEvaluation.value.directExperience)
				certData.evaluation.directExperience = this.userEvaluation.value.directExperience;
			if (this.userEvaluation.value.evaluationValidated)
				certData.evaluation.evaluationValidated = this.userEvaluation.value.evaluationValidated;
			

			this.certifyData(certData).then((certResult) => {
				let toSave: any = new Object();
				Object.assign(toSave, this._originalEntity);
				if (!toSave.evaluation) toSave.evaluation = {};
				
				if (!toSave.evaluation.usersEvaluation) toSave.evaluation.usersEvaluation = [];
				
				let cData: any = new Object();
				Object.assign(cData,this.userEvaluation.value);
				cData.certifiedData = this.certifiedData;
				
				toSave.evaluation.usersEvaluation.push(cData);

				// let index = (<any[]>toSave.evaluation.usersEvaluation).findIndex((ueval) => {
				// 	return ueval.userId == this._authService.getClaims().sub;
				// })

				// if (index < 0) {
				// 	toSave.evaluation.usersEvaluation.push(cData);
				// }
				// else {
				// 	toSave.evaluation.usersEvaluation[index] = cData;
				// }
				this._restaurantProvider.saveOrUpdateRestaurant(DOMAINS_CONFIG.ROMANESCO, this.marshalData(toSave))
					.pipe(
						catchError(error => {
							return of(null);
						})
					)
					.subscribe(
						(result) => {
							if (result) {
								this.activeModal.close(true);
							}
							
						},
						(error) => {
							this.activeModal.close(false);
						}
					)
			})
        }
    }

	protected certificationConfig = {
		name: "pOsti",
		identity: "virgilio.maretto@posti.world"
	};

	protected currentDomainConfig = {
		domainId: "ROMANESCO",
		name: "Romanesco",
		title: "Tipico Romanesco",
		showBlockChainUrl: true,
		downloadExcelEnabled: true,
		deleteEntityEnabled: true,
		certIdField: "id",
		fields: [
		],
		map: (entity) => {return entity},
		entityLabel: "ristoranti",
		managedEntity: [
			{
				entityType: "Restaurant",
				entityLabel: "Ristorante"
			}
		],
		marshalCerdData: (data) => {
			// data.evaluation.evaluationDate = moment(data.evaluation.evaluationDate).format("DD/MM/YYYY");
			data.evaluation.certificationDate = moment().format("DD/MM/YYYY");
			
			return data;
		},
		resetCertificationFieldsSpec: (data?: any) => {
			return [
				{fieldId: "sign", fieldLabel: "Nome locale"},
				{fieldId: "representative", fieldLabel: "rappresentante"},
				{fieldId: "email", fieldLabel: "email"},
				{fieldId: "address", fieldLabel: "indirizzo"},
				{fieldId: "city", fieldLabel: "comune"},
				{fieldId: "province", fieldLabel: "provincia"},
				{fieldId: "evaluation.evaluationDate", fieldLabel: "data della valutazione"},
				{fieldId: "evaluation.certificationDate", fieldLabel: "data della registrazione in blockchain"},
				{fieldId: "evaluation.evaluationStarter", fieldLabel: "Valutazione ANTIPASTI"},
				{fieldId: "evaluation.evaluationFirstAndSoup", fieldLabel: "Valutazione PRIMI e MINESTRE"},
				{fieldId: "evaluation.evaluationSeconds", fieldLabel: "Valutazione SECONDI"},
				{fieldId: "evaluation.evaluationSide", fieldLabel: "Valutazione CONTORNI"},
				{fieldId: "evaluation.evaluationDessert", fieldLabel: "Valutazione DESSERT e FORMAGGII"},
				{fieldId: "evaluation.evaluationOil", fieldLabel: "Valutazione CARTA OLI"},
				{fieldId: "evaluation.evaluationWine", fieldLabel: "Valutazione VINI"},
				{fieldId: "evaluation.useTipicalProduct", fieldLabel: "Valutazione PRODOTTI TIPICI"},
				{fieldId: "evaluation.evaluationMenu", fieldLabel: "Valutazione RICETTA TIPICA"},
				{fieldId: "evaluation.indiceTecnico", fieldLabel: "Indice di romanita tecnico"},
				{fieldId: "evaluation.indiceQualitativo", fieldLabel: "indice di romanità qualitativa"},
				{fieldId: "evaluation.evaluationDescription", fieldLabel: "Giudizio descrittivo"},
				{fieldId: "evaluation.evaluationValidated", fieldLabel: "processo di valutazione validato dal valutatore"}
			];
		}
	};


	private path = (obj,path) => {
        try{
            return eval("obj."+path);
        } catch(e) {
            return undefined;
        }
    }

	protected  certifyData = async (dataToCertify: any, secret?: string) => {
		let data = this.currentDomainConfig.marshalCerdData(dataToCertify);
		let certificationFieldsSpec: any = this.currentDomainConfig.resetCertificationFieldsSpec(dataToCertify);
		
		
		let fieldsToCertify : any = {};
		for (const element of certificationFieldsSpec) {
			if (element.isArray) {
				fieldsToCertify = [];
				for (const obj of data[element.fieldId]) {
					if (!obj.removedFromBlockChain) {
						let objToCert: any = {};
						for (const spec of element.arrayFieldsSpec) {
							if (this.path(obj, spec.fieldId)) {
								objToCert[spec.fieldLabel] =  (spec.startValue) ? spec.startValue + this.path(obj, spec.fieldId): this.path(obj, spec.fieldId);
								if (spec.isImage) {
									await this._restaurantProvider.getImageHash(this.path(obj, spec.fieldId)).toPromise().then((imgHash) => {
										objToCert[spec.fieldLabel.replaceAll("_url","_hash")] = imgHash;
									});
								}
	
							}
						}
						fieldsToCertify.push(objToCert);
					}
					
				}
			}
			else {
				if (element.isImage) {
					fieldsToCertify[element.fieldLabel + "_url"] =  (element.startValue) ? element.startValue + this.path(data, element.fieldId): this.path(data, element.fieldId);
					await this._restaurantProvider.getImageHash(this.path(data, element.fieldId)).toPromise().then((imgHash) => {
						fieldsToCertify[element.fieldLabel+ "_hash"]= imgHash;
					});
				}
				else {
					fieldsToCertify[element.fieldLabel] =  (element.startValue) ? element.startValue + this.path(data, element.fieldId): this.path(data, element.fieldId);

				}

			}
		}
		let toSend: any = new Object();
		Object.assign(toSend, this.certificationConfig);
		toSend.azienda = this.currentDomainConfig.domainId;
		toSend.cert_id = "user_"+this._authService.getClaims().sub+ "_" + this.currentDomainConfig.domainId +"_evaluation_" + this.path(data, this.currentDomainConfig.certIdField);

		toSend.data = fieldsToCertify;

		this.certifiedData = this._chainService.getSignature(toSend,this.walletForm.value.privateKey);
		return this._chainService.certifyEntity(toSend, this.walletForm.value.privateKey).toPromise();
	}
}


