import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ClaimedNFT, Dashboard, Roles, UserProfile, WalletInfo } from 'app/models/profile.models';
import { BASE_FORM_PATH } from 'app/pages/forms/routing-constants';
import { ACR, ACRService } from 'app/services/acl.service';
import { AuthService } from 'app/services/auth.service';
import { BlockChainService } from 'app/services/blockchain.service';
import { domainConfig } from 'app/services/DomainConfigProvider';
import { ProfileService } from 'app/services/profile.service';
import { DOMAINS_CONFIG } from 'app/services/restaurant.service';
import { environment } from 'environments/environment';

declare var clientLib;
import { saveAs } from 'file-saver';
import moment from 'moment';

@Component({
	selector: 'user-profile-page',
	templateUrl: './user-profile.page.html'
})

export class UserProfilePage implements OnInit {
	pageLoaded = false;
	userProfile: UserProfile;
	formGroup: FormGroup;
	address: FormGroup;
	contacts: FormGroup;
	companyAddress: FormGroup;
	company: FormGroup;
	walletInfo: FormGroup;
	claimedNFT: FormArray;
	privateKey: string;
	userClaimedNFT: ClaimedNFT[] = [
		// {
		// 	cert_id: "62179cb6c857af6160ebd109",
		// 	domain: DOMAINS_CONFIG.ROMANESCO,
		// 	claimedFieldName: "sign"
		// }
	];

	availableNftToClaim: ClaimedNFT;

	possibileMediaTypes = ['image/png', 'image/jpeg', 'image/gif'];
	activeTab = "general";

	dashboardsEnabled = [];
	acrEnabled = [];

	constructor(
		private _activatedRoute: ActivatedRoute,
		private _router: Router, 
		private _chainService: BlockChainService, private _userProfileService: ProfileService, private _fb: FormBuilder, private _authService: AuthService, private _acrService: ACRService) {

	}

	public get delegationEnabled() {
		return this._userProfileService.isInRole(Roles.COMPANY_ADMIN)
	}

	private initDashboard = (profile: UserProfile, acrs: ACR[]) => {
		
		
		if (profile && profile.enabledDashboards) {
			profile.enabledDashboards.forEach((d: Dashboard) => {
				if (d == Dashboard.EVALUATION_ROMANESCO) { this.dashboardsEnabled.push({routerLink: "/evaluation-romanesco", label: "ROMANESCO: Dashboard di valutazione commissari"})}
				if (d == Dashboard.ROMANESCO) { this.dashboardsEnabled.push({routerLink: "/"+domainConfig.ROMANESCO.domainId+"/entity-list", label: domainConfig.ROMANESCO.name})}
				if (d == Dashboard.GIUSTA) { this.dashboardsEnabled.push({routerLink: "/"+domainConfig.GIUSTA.domainId+"/entity-list", label: domainConfig.GIUSTA.name})}
				if (d == Dashboard.PECORA_NERA) { this.dashboardsEnabled.push({routerLink: "/"+domainConfig.PECORA_NERA.domainId+"/entity-list", label: domainConfig.PECORA_NERA.name})}
				if (d == Dashboard.PECORA_NERA2) { this.dashboardsEnabled.push({routerLink: "/PECORA_NERA_2/entity-list", label: domainConfig.PECORA_NERA_2.name + " - ispettori"})}
				if (d == Dashboard.HQFAgricola) { this.dashboardsEnabled.push({routerLink: "/"+domainConfig.hqfagricola_demo.domainId+"/entity-list", label: domainConfig.hqfagricola_demo.name})}
				if (d == Dashboard.casearia) { this.dashboardsEnabled.push({routerLink: "/"+domainConfig.casearia.domainId+"/entity-list", label: domainConfig.casearia.name})}
				if (d == Dashboard.casearia_pecorino) { this.dashboardsEnabled.push({routerLink: "/"+domainConfig.casearia_pecorino.domainId+"/entity-list", label: domainConfig.casearia_pecorino.name})}
				if (d == Dashboard.agricola_san_maurizio) { this.dashboardsEnabled.push({routerLink: "/"+domainConfig.agricola_san_maurizio.domainId+"/entity-list", label: domainConfig.agricola_san_maurizio.name})}
				if (d == Dashboard.riso_buono) { this.dashboardsEnabled.push({routerLink: "/"+domainConfig.riso_buono.domainId+"/entity-list", label: domainConfig.riso_buono.name})}
				if (d == Dashboard.USER_LIST_ADMIN) { this.dashboardsEnabled.push({routerLink: "/admin/users-list", label: "Amministrazione utenze"})}
				if (d == Dashboard.biolu) { this.dashboardsEnabled.push({routerLink: "/"+domainConfig.biolu.domainId+"/entity-list", label: domainConfig.biolu.name})}

			})
		}

		if (acrs) {
			// this._acrService.getAll().subscribe((r) => {
			// 	r.forEach((acr => {
			// 		this._acrService.deleteACR(acr).subscribe(() => {})
			// 	}))
			// })
			
			acrs.forEach((acr => {
				this.acrEnabled.push(
					{
						routerLink: "/form/" + acr.entityDomain+"/"+acr.entityId + "?returnUrl=/user-profile",
						label: acr.entityDomain + " - " + acr.entityName,
						navigationPath: [BASE_FORM_PATH, acr.entityDomain , acr.entityId]
					});
			}))
		}
	}

	ngOnInit() {
		this._userProfileService.getLoggedProfile().subscribe((result) => {
			this.userProfile = result;

			this.initForm();
			this.formGroup.patchValue(this.userProfile);
			
			if (this.userProfile.claimedNFT)
				this.userClaimedNFT = this.userProfile.claimedNFT;
			else
				this.userClaimedNFT = [];
			this.pageLoaded = true;

			console.log(window.location.search);
			
			const urlParams = new URLSearchParams(window.location.search);
			let nftClaim: ClaimedNFT = new ClaimedNFT();
			
			if (urlParams.has("cert_id")) nftClaim.cert_id = urlParams.get("cert_id");
			if (urlParams.has("cert_id_field_name")) nftClaim.certIdFieldName = urlParams.get("cert_id_field_name");
			if (urlParams.has("domain")) nftClaim.domain = <DOMAINS_CONFIG>urlParams.get("domain");
			if (urlParams.has("claimed_field_name")) nftClaim.claimedFieldName = urlParams.get("claimed_field_name");

			if (nftClaim.cert_id && nftClaim.domain) {

				if (this.userClaimedNFT.findIndex((nft: ClaimedNFT) => {return nft.cert_id == nftClaim.cert_id}) < 0)
					this.availableNftToClaim = nftClaim;
				
				if (this.availableNftToClaim) {
					if (!this.availableNftToClaim.claimedFieldName && domainConfig[this.availableNftToClaim.domain] && (<any>domainConfig[this.availableNftToClaim.domain]).claimedFieldName) {
						this.availableNftToClaim.claimedFieldName = (<any>domainConfig[this.availableNftToClaim.domain]).claimedFieldName;
					}
						
				}
			}

			this._acrService.getACROfProfile(this.userProfile).subscribe((acrs: ACR[]) => {
				this.initDashboard(this.userProfile,acrs);
			})
			

		})
	}

	public goToEnabledDashboard = ($event: MouseEvent, navigationPath): void => {
		$event.preventDefault();
		$event.stopPropagation();

		this._activatedRoute.paramMap.subscribe(qp => {
			const queryParams = {};
			queryParams['returnUrl'] = "/user-profile"
			this._router.navigate(navigationPath, { queryParams: queryParams });
		})
	}

	claimNow() {
		
		this._chainService.claimNft(this.availableNftToClaim).subscribe(
			(result) => {
				if (! this.userProfile.claimedNFT) this.userProfile.claimedNFT = [];
				this.userProfile.claimedNFT.push(this.availableNftToClaim);
				let fg: FormGroup = this._fb.group({
					cert_id: [null],
					domain: [null],
					claimedFieldName: [null],
					certIdFieldName: []
				})
				fg.patchValue(this.availableNftToClaim);
				this.claimedNFT.push(fg);
				this.availableNftToClaim = null;
				this._userProfileService.saveOrUpdateProfile(this.formGroup.value).subscribe((r) => {
					this.userProfile = r;
					this.userClaimedNFT = this.userProfile.claimedNFT;
				})
				
			},
			(error) => {console.log(error);
			}
		);
	}
	public get profileImage() {
		if (this.userProfile && this.userProfile.avatarId)
			return environment.services.mediaContentsBasePath + "/" + this.userProfile.avatarId;
		return "assets/img/icons/face-icon-0.jpg.png"
	}

	public get firstName() {
		if (this.userProfile && this.userProfile.firstName)
			return this.userProfile.firstName;
	}
	public get lastName() {
		if (this.userProfile && this.userProfile.lastName)
			return this.userProfile.lastName;
	}

	public get isProfileComplete(): any {
		return " => " + (this.userProfile && this.userProfile.id != null && this.userProfile.id != undefined);
	}

	private initForm = (): void => {

		this.address = this._fb.group({
			address: [null],
			cap: [null],
			city: [null],
			province: [null]
		});
		this.contacts = this._fb.group({
			mobilePhone: [null],
			fixedPhone: [null],
			pec: [null],
			alternativeEmail: [null]
		});


		this.companyAddress = this._fb.group({
			address: [null],
			cap: [null],
			city: [null],
			province: [null]
		});

		this.company = this._fb.group({
			company: [null],
			organization: [null],
			address: this.companyAddress
		});
		this.walletInfo = this._fb.group({
			creationDate: [null],
			pubKey: [null]
		});
		this.claimedNFT = this._fb.array([]);

		this.formGroup = this._fb.group({
			userId: [null],
			avatarId: [null],
			firstName: [null, Validators.required],
			lastName: [null, Validators.required],
			email: [null, Validators.required],
			fiscalCode: [null],

			id: [null],
			lastUpdateDate: [null],
			roles: [[]],
			walletInfo: this.walletInfo,
			claimedNFT: this.claimedNFT,
			company: this.company,
			contacts: this.contacts,
			address: this.address,
			enabledDashboards: [[]]
		});

		
		if (this.userProfile.claimedNFT ) {
			if (this.userProfile.claimedNFT.length > 0) {
				this.userProfile.claimedNFT.forEach((nft) => {
					let fg: FormGroup = this._fb.group({
						cert_id: [null],
						domain: [null],
						claimedFieldName: [null],
						certIdFieldName: []
					})
					fg.patchValue(nft);
					this.claimedNFT.push(fg);
				})
			}
			else {
				this.userProfile.claimedNFT = [];
			}
			
		}
		
	}

	setActiveTab(tab) {
		this.activeTab = tab;
	}


	onSaveAccountInfo = () => {

	}

	get baseInfoValid(): boolean {
		return this.formGroup.get("fiscalCode").isValid();
	}
	
	savebaseInfo() {
		if (this.baseInfoValid) {
			this._userProfileService.saveOrUpdateProfile(this.formGroup.value).subscribe((result) => {
				this.userProfile = result;
			})
		}
	}
	get personalInfoValid(): boolean {
		return this.address.valid && this.formGroup.get("fiscalCode").isValid();
	}
	savePersonalInfo() {
		if (this.personalInfoValid) {
			this._userProfileService.saveOrUpdateProfile(this.formGroup.value).subscribe((result) => {
				this.userProfile = result;
			})
		}
	}
	get contactsValid(): boolean {
		return this.address.valid;
	}
	saveContacts() {
		if (this.contactsValid) {
			this._userProfileService.saveOrUpdateProfile(this.formGroup.value).subscribe((result) => {
				this.userProfile = result;
			})
		}
	}

	get companyValid(): boolean {
		return this.address.valid;
	}
	saveCompany() {
		if (this.companyValid) {
			this._userProfileService.saveOrUpdateProfile(this.formGroup.value).subscribe((result) => {
				this.userProfile = result;
			})
		}
	}

	walletCreating= false;
	get walletAlreadyRequired(): boolean {
		return (this.userProfile && this.userProfile.walletInfo && this.userProfile.walletInfo.pubKey)?true:false;
	}
	createNewWallet = () => {
		this.walletCreating = true;
		let account = clientLib.createAccount();
		let pk = account[0];
		let sk = account[1];
		this._chainService.createWallet({pubkey: pk})
			.subscribe((result => {
				console.log("ciao....", result);
				let data: UserProfile = this.formGroup.value;
				data.walletInfo = new WalletInfo();
				data.walletInfo.creationDate = moment().utc().format();//valueOf();//format("YYYY-MM-DDThh:mm:ss");
				console.log("data.walletInfo.creationDate " + data.walletInfo.creationDate);
				
				data.walletInfo.pubKey = pk;
				this._userProfileService.saveOrUpdateProfile(data).subscribe((result) => {
					this.userProfile = result;
					this.privateKey = sk;
					this.walletCreating = false;
				},
				(error) => {
					this.walletCreating = false;
				}
				)
			}),
			(error) => {
				this.walletCreating = false;
			}
			);
	}

	saveOnFile = () => {
		if (this.privateKey) {
			var blob = new Blob([this.privateKey], { type: "text/plain;charset=utf-8" });
			saveAs(blob, "wallet.txt", { type: "text/plain;charset=utf-8" });
		}
	}

	logout = () => {
		this._authService.logout();
	}
}