// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
	
    production: false,
	authentication: {
		issuer: "https://iam.posti.world/realms/posti",
		silentRefreshRedirectUri: "/assets/authentication/silent-refresh.html",
		clientId: "posti-bo",
		responseType: "code",
		scope: "openid profile offline_access roles phone address",
		redirectUri: null,
		postLogoutRedirectUri: null
	},
	services: {
		blockChainService: "https://dashboard.posti.world/new-api-service/blockchain",
		restaurantService: "https://dashboard.posti.world/new-api-service/api/v1/bo/restaurants",
		mediaContentsBasePath:		"https://dashboard.posti.world/new-api-service/api/v1/bo/images",
		// blockChainService: "https://dashboard.posti.world/test-api-service/blockchain",
		newBlockchainBasePath: "https://api-gateway.posti.world/blockchain-service",
		
		// blockchainUrlVerify: 		"https://verifier.posti.world/{domain}/{id}/verify",

		birraPeroni: "https://api-gateway.posti.world/blockchain-service",


		categoryServiceBasePath:	"https://api-gateway.officinaedisanto.cloud/new-api/contents-service/api/v1/bo/categories",
		contentsServiceBasePath:	"https://api-gateway.officinaedisanto.cloud/new-api/contents-service/api/v1/bo/contents",
		macroareaServiceBasePath:	"https://api-gateway.officinaedisanto.cloud/new-api/contents-service/api/v1/bo/macroareas",
		sharingServiceBasePath:		"https://api-gateway.officinaedisanto.cloud/new-api/contents-service/api/v1/bo/{{entityType}}",
		domainEntityServiceBasePath:"https://api-gateway.officinaedisanto.cloud/new-api/contents-service/api/v1/bo/domainentities",

		appSettingsBasePath:		"https://api-gateway.officinaedisanto.cloud/new-api/core-service/api/v1/bo",
		baseUserServicesBasePath:	"https://api-gateway.officinaedisanto.cloud/new-api/core-service/api/v1/users",

		// mediaContentsBasePath:		"https://api-gateway.officinaedisanto.cloud/new-api/files-service/api/v1/bo/files",
		objectSpaceManagementPath:	"https://api-gateway.officinaedisanto.cloud/new-api/osm-service/api/v1/bo"
		
	},
	certificationConfig: {
		DEFAULT_CONFIG: {
			name: "pOsti",
			identity: "virgilio.maretto@posti.world",
			pubkey: "0x94A7Ae889eA2333216e61a644345B0086D23E4De"
		},
		PECORA_NERA: {
			external_api: "almaviva",
			name: "pOsti"
		},
		PECORA_NERA_2: {
			external_api: "almaviva",
			name: "pOsti"
		}
	}
};
